<template>
  <div>
    <v-dialog v-model="dialogs.remove" max-width="300">
      <v-card>
        <v-card-title>
          Remove member?
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogs.remove = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-3">
          <v-btn small @click="removeMember()" color="error" class="text-capitalize mr-3"
            >Remove <v-icon>mdi-trash-can</v-icon></v-btn
          >
          <v-btn small @click="dialogs.remove = false" color="grey" class="text-capitalize" outlined
            >Cancel</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
      <v-text-field
        class="member-email"
        label="Email"
        :rules="rules.requiredRule"
        :disabled="disabled"
        v-model="formData.email"
        outlined
      ></v-text-field>
      <v-slide-y-transition>
        <div v-if="userNotFound">
          <v-alert type="error" dense text>
            This email address is not registered with Peeps<br />
          </v-alert>
        </div>
      </v-slide-y-transition>
      <div class="d-flex align-center">
        <strong>
          {{ formData.fullName }}
        </strong>
        <v-spacer></v-spacer>
        <BaseConfirmAction v-slot="{ on, confirm, cancel }" v-if="allowRemove">
          <div v-if="formData.inactive">
            <v-btn color="success" small class="text-none" @click="deactive()"
              >Activate account</v-btn
            >
          </div>
          <div v-else>
            <v-btn v-if="!confirm" color="secondary" small class="v-btn--active text-none" v-on="on"
              >Deactivate Account</v-btn
            >
            <div v-if="confirm">
              <div>
                <v-btn @click="deactive()" small color="error" class="text-none">Deactivate</v-btn>
                <v-btn small @click="cancel" class="text-none" text color="grey">Cancel</v-btn>
              </div>
            </div>
          </div>
        </BaseConfirmAction>
      </div>
      <v-row class="mt-2">
        <v-col cols="6">
          <DateSelect v-model="formData.memberSince" label="Member since" :outlined="false" />
        </v-col>
        <v-col cols="6">
          <v-text-field label="Team" v-model="formData.team"></v-text-field>
        </v-col>
      </v-row>
      <div>
        <h6 class="text-h6">Devices/Browser</h6>
        <v-row no-gutters class="mt-0 pt-0">
          <v-col cols="12" sm="6">
            <v-textarea
              class="mr-2"
              rows="1"
              auto-grow
              label="Desktop"
              v-model="formData.deviceDesktop"
            ></v-textarea>
          </v-col>
          <v-col cols="12" sm="6">
            <v-textarea
              auto-grow
              rows="1"
              label="Mobile"
              v-model="formData.deviceMobile"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-select
          v-model="formData.wirelessCarrier"
          label="Wireless Carrier"
          :items="wirelessCarrierList"
        ></v-select>
      </div>
      <Contact
        :value="contactData"
        ref="contact"
        :fields="contactFields"
        gps
        :format-phone-number="formatPhoneNumber"
      />
      <div class="mb-4">
        <v-row align="center">
          <v-col cols="12">
            <h3 class="text-h6">Permissions</h3>
            <div>
              <SelectPermission v-model="formData.permissions" />
            </div>
          </v-col>
        </v-row>
        <div>
          <CmsHtml class="mt-3" :value="OrganizationPermissionDescription" />
        </div>
      </div>
      <AlertMessge :error="error" />
      <BaseFormAction
        :x-large="false"
        :submit-label="submitLabel"
        :disabled="disabledSubmit"
        @click="onSubmit()"
        @cancel="onCancel()"
        :loading="loading"
      />
    </v-form>
  </div>
</template>

<script>
import ruleMixin from '../../mixins/rules';
import AlertMessge from '../Core/AlertMessage.vue';
import config from '../../config';
import { fullName } from '../../utils';
const api = config.api();

export default {
  components: {
    AlertMessge,
    CmsHtml: () => import('../../components/cms/Html.vue'),
    SelectPermission: () => import('./SelectPermission.vue'),
    DateSelect: () => import('../../components/Core/DateField.vue'),
    Contact: () => import('../Contact.vue')
  },
  mixins: [ruleMixin],
  props: {
    OrganizationPermissionDescription: {
      type: [String, Object],
      default: 'OrganizationPermissionDescription'
    },
    user: {
      type: String,
      required: false
    },
    submitLabel: {
      type: String,
      default: 'Add'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    },
    id: {
      type: String,
      required: true
    },
    allowRemove: {
      type: Boolean,
      default: false
    },
    formatPhoneNumber: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    data: {
      handler(data) {
        if (data) {
          this.formData = { ...this.formData, ...data };
        }
      },
      immediate: true
    },
    'formData.email': {
      handler(email) {
        if (email) {
          this.userInfo = null;
          this.fetchUser(email);
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      dialogs: { remove: false },
      formData: {
        email: '',
        fullName: '',
        permissions: {},
        inactuve: false
      },
      valid: true,
      loading: false,
      error: '',
      email: '',
      perms: [],
      userInfo: null,
      team: '',
      nameLoading: false,
      disabledSubmit: false,
      userNotFound: false,
      userFullName: {},
      deviceDesktop: '',
      deviceMobile: '',
      phoneNumber: '',
      homeCoordinates: '',
      wirelessCarrierList: ['ATT', 'Verizon', 'T-Mobile', 'Sprint', 'Other']
    };
  },
  created() {
    if (!this.data) {
      this.reset();
    }
  },
  computed: {
    contactData() {
      if (!this.formData) {
        return null;
      }
      const { street, state, city, zip, address, phoneNumber, homeCoordinates, phone } =
        this.formData;
      return {
        street,
        state,
        city,
        zip,
        address,
        phone: phone || phoneNumber,
        gps: homeCoordinates
        // gps: this.formData.homeCoordinates
      };
    },
    contactFields() {
      return ['phone', 'address'];
    },
    fullName() {
      if (this.userInfo) {
        const fullName = `${this.userInfo.first_name || ''} ${this.userInfo.last_name || ''}`;
        return fullName.trim();
      } else {
        return '';
      }
    }
  },
  methods: {
    deactive() {},
    fetchUser(email) {
      if (this.formData.fullName) {
        return;
      }
      this.userInfo = null;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.nameLoading = true;
        // this.disabledSubmit = true;
        this.userNotFound = false;

        this.$axios
          .get(`${api.cloudFunction}/userInfo?email=${String(email).toLowerCase()}`)
          .then(res => {
            if (res.status === 200 && res.data.data) {
              res.data.data.users.forEach(u => {
                if (String(u.email).toLowerCase() === String(email).toLowerCase()) {
                  this.userInfo = u;
                  this.formData.fullName = fullName(u);
                }
              });

              const validateEmail = email => {
                return email.match(
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
              };

              if (!this.userInfo) {
                if (validateEmail(this.formData.email)) {
                  this.userNotFound = true;
                }
              }
            }
          })
          .finally(() => {
            this.nameLoading = false;
            // this.disabledSubmit = false;
          });
      }, 500);
    },
    removeMember() {},
    reset() {
      this.formData = {
        email: '',
        fullName: '',
        team: '',
        permissions: {}
      };
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
    submit() {},
    cancel() {
      this.reset();
      this.$emit('cancel');
    },
    onSubmit() {
      this.$refs.form.validate();
      if (!this.formData.email) {
        return;
      }
      const { address, phone } = this.$refs.contact.getData();

      const newData = {
        address,
        phone
      };

      this.formData = { ...this.formData, ...newData };
      this.submit();
    },
    onCancel() {
      this.cancel();
    },

    hasPerm(perm) {
      return this.perms.includes(perm);
    },
    addPerm(perm) {
      this.perms.push(perm);
    },
    removePerm(index) {
      this.perms.splice(index, 1);
    },
    togglePerm(perm) {
      const index = this.perms.indexOf(perm);
      if (index > -1) {
        this.removePerm(index);
      } else {
        this.addPerm(perm);
      }
    }
  }
};
</script>

<style lang="scss">
.member-email {
  .v-input__control {
    .v-text-field__details {
      display: none !important;
    }
  }
}
</style>
