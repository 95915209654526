<script>
import BaseForm from './MemberBaseForm.vue';
import Organization from '../../services/organization';
const org = new Organization();
export default {
  extends: BaseForm,
  props: {
    user: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  watch: {
    user: {
      handler(user) {
        this.fetchData();
        if (user) {
          this.userInfo = null;
          this.fetchUser(user);
        }
      },
      immediate: true
    }
  },
  methods: {
    deactive() {
      org.deactive({ id: this.id, email: this.user });
      this.$emit('success');
    },
    fetchData() {
      org.getPermissions(this.id).then(res => {
        const data = res.data();
        const member = (data.members || []).filter(member => member.email === this.user)[0];
        if (member) {
          this.formData = { ...this.formData, ...member };
        }
      });
    },
    removeMember() {
      org.removeMember({ id: this.id, email: this.user }).then(() => {
        this.$emit('success');
      });
    },
    submit() {
      org.updateMember({ id: this.id, member: this.formData }).then(() => {
        this.reset();
        this.$emit('success');
      });
    }
  }
};
</script>

<style>
</style>
